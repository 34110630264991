import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styled from "@emotion/styled";
import TopChart from "./TopChart";
import { fetchData } from "../../../../services/fetchData";
import { REACT_APP_TEST_RESULT_BASE_URL } from "../../../../utils/constants";
import { TablePagination } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function ExamAnalysis() {
  const { formatMessage, locale } = useIntl();
  const typesData = [
    { name: "REGIONS", idName: "regionId" },
    { name: "DISTRICTS", idName: "districtId" },
    { name: "SCHOOLS", idName: "schoolId" },
    { name: "STUDENTS", idName: "studentId" },
  ];

  const [types, setTypes] = useState(typesData);
  const examId = 25;
  const classes = useStyles();
  const [analysis, setAnalysis] = useState([]);

  const [top10, setTop10] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    rows_per_page: 20,
    page_number: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [params, setParams] = useState({
    analysisType: "REGIONS",
    page: pageInfo.page_number,
  });

  const [filterNames, setFilterNames] = useState({
    region: "",
    district: "",
    school: "",
  });

  const filters = [
    {
      name: formatMessage({ id: "Kazakhstan" }),
      selected: true,
      id: "COUNTRY",
    },
    {
      name: filterNames.region,
      selected: params.regionId,
      id: "REGION",
    },
    {
      name: filterNames.district,
      selected: params.districtId,
      id: "DISTRICT",
    },
    {
      name: filterNames.school,
      selected: params.schoolId,
      id: "SCHOOL",
    },
    {
      name: "Студент",
      selected: false,
      id: "STUDENT",
    },
  ];

  const RowHeaders = [
    {
      value: "#",
    },
    {
      value: formatMessage({ id: "regionName" }),
    },
    {
      value: formatMessage({ id: "numberOfParticipants" }),
    },
    {
      value: formatMessage({ id: "averagePoint" }),
    },
    {
      value: formatMessage({ id: "actions" }),
    },
  ];
  const RowHeadersStudents = [
    {
      value: "#",
    },
    {
      value: formatMessage({ id: "fullName" }),
    },

    {
      value: formatMessage({ id: "points" }),
    },
    {
      value: formatMessage({ id: "actions" }),
    },
  ];

  const getAnalysis = async () => {
    try {
      const result = await fetchData(
        "get",

        `${REACT_APP_TEST_RESULT_BASE_URL}/exam-analysis/${examId}?` +
          new URLSearchParams(params)
      );

      if (result.status == 200 || result.status == 204) {
        // console.log(result?.data?.data?.data);
        setAnalysis(result?.data?.data?.data);
        setPageInfo((prev) => ({
          ...prev,
          page_number: result?.data?.data?.page_number,
          total_count: result?.data?.data?.total_count,
          total_pages: result?.data?.data?.total_pages,
        }));

        if (params.page === 0) {
          setTop10(result?.data?.data?.data?.slice(0, 10));
        }
      }
    } catch (error) {
    }
  };

  const handleClick = (id, title) => {
    setPageInfo({
      rows_per_page: 20,
      page_number: 0,
      total_count: 0,
      total_pages: 0,
    });
    switch (params.analysisType) {
      case "REGIONS":
        setParams((prev) => ({
          ...prev,
          page: 0,
          size: 20,
          analysisType: "DISTRICTS",
          regionId: id,
        }));
        setFilterNames((prev) => ({ ...prev, region: title }));
        setTypes(typesData.filter((i) => i.name !== "REGIONS"));
        break;
      case "DISTRICTS":
        setParams((prev) => ({
          ...prev,
          page: 0,
          size: 20,
          analysisType: "SCHOOLS",
          districtId: id,
        }));
        setFilterNames((prev) => ({ ...prev, district: title }));
        setTypes(
          typesData.filter(
            (i) => i.name !== "DISTRICTS" && i.name !== "REGIONS"
          )
        );
        break;
      case "SCHOOLS":
        setParams((prev) => ({
          ...prev,
          page: 0,
          size: 20,
          analysisType: "STUDENTS",
          schoolId: id,
        }));
        setFilterNames((prev) => ({ ...prev, school: title }));
        setTypes(typesData.filter((i) => i.name == "STUDENTS"));
        break;
      default:
        break;
    }
  };

  const handleRemove = (id) => {
    const _params = params;
    _params.page = 0;
    _params.size = 20;
    switch (id) {
      case "STUDENT":
        delete _params["studentId"];
        setParams(_params);
        setTypes(
          typesData.filter(
            (i) =>
              i.name !== "REGION" &&
              i.name !== "DISTRICT" &&
              i.name !== "SCHOOL"
          )
        );
        break;
      case "SCHOOL":
        delete _params["schoolId"];

        if (_params.districtId && _params.regionId) {
          _params.analysisType = "SCHOOLS";
          setTypes(
            typesData.filter(
              (i) => i.name !== "REGIONS" && i.name !== "DISTRICTS"
            )
          );
          break;
        } else if (_params.regionId) {
          _params.analysisType = "DISTRICTS";
          setTypes(typesData.filter((i) => i.name !== "REGIONS"));
          break;
        } else {
          _params.analysisType = "REGIONS";
          setTypes(typesData);
          break;
        }

        setParams(_params);
        break;

      case "DISTRICT":
        delete _params["districtId"];
        delete _params["schoolId"];
        if (_params.regionId) {
          setTypes(typesData.filter((i) => i.name !== "REGIONS"));
          _params.analysisType = "DISTRICTS";
        } else {
          _params.analysisType = "REGIONS";
          setTypes(typesData);
        }
        setParams(_params);
        break;
      case "REGION":
        delete _params["regionId"];
        delete _params["districtId"];
        delete _params["schoolId"];
        _params.analysisType = "REGIONS";
        setParams(_params);
        setTypes(typesData);

        break;
      default:
        break;
    }
    getAnalysis();
  };

  const handleChangePage = (event, newPage) => {
    setPageInfo((prev) => ({ ...prev, page_number: newPage }));
    setParams((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    setPageInfo((prev) => ({
      ...prev,
      page_number: 0,
      rows_per_page: parseInt(event.target.value, 10),
    }));
    setParams((prev) => ({
      ...prev,
      page: 0,
      size: parseInt(event.target.value, 10),
    }));
  };

  useEffect(() => {
    getAnalysis();
    // console.log("params", params);
  }, [params]);

  return (
    <Wrapper>
      {/* <Filters /> */}
      <TopChart
        types={types}
        // analysisType={analysisType}
        // setAnalysisType={setAnalysisType}
        params={params}
        setParams={setParams}
        filters={filters}
        handleRemove={handleRemove}
        top10={top10}
        // analysis={analysis}
      />
      <TableContainer component={Paper} elevation={0}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell>#</TableCell>
              <TableCell>{formatMessage({ id: "regionName" })}</TableCell>
              <TableCell align="left">
                {formatMessage({ id: "numberOfParticipants" })}
              </TableCell>
              <TableCell align="left">
                {formatMessage({ id: "averagePoint" })}
              </TableCell>
              <TableCell align="left">
                {formatMessage({ id: "actions" })}
              </TableCell> */}
              {params.analysisType === "STUDENTS"
                ? RowHeadersStudents.map((r) => (
                    <TableCell>{r.value}</TableCell>
                  ))
                : RowHeaders.map((r) => <TableCell>{r.value}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {analysis.map((item, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row" align="left">
                  {index + 1 + pageInfo.page_number * pageInfo.rows_per_page}
                </TableCell>
                <TableCell component="th" scope="row">
                  {item[`title_${locale}`] || item.full_name}
                </TableCell>
                {params.analysisType !== "STUDENTS" && (
                  <TableCell align="left">{item.participated}</TableCell>
                )}
                <TableCell align="left">
                  {Math.floor(item.average * 10) / 10 || item.point}
                </TableCell>
                {/* <TableCell align="left">{item.carbs}</TableCell> */}
                <TableCell align="left">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleClick(item.id, item.title_ru);

                      // getAnalysis(p);
                    }}
                  >
                    {formatMessage({ id: "moreInfo" })}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[20, 40, 60]}
          component="div"
          count={pageInfo.total_count}
          page={pageInfo.page_number}
          rowsPerPage={pageInfo.rows_per_page}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          // onPageChange={handleChangePage}
          onChangePage={handleChangePage}
          // labelDisplayedRows={"ru"}

          labelDisplayedRows={({ from, to, count }) => (
            <FormattedMessage
              id="labelDisplayedRows"
              values={{ from: from, to: to, count: count }}
            />
          )}
          labelRowsPerPage={formatMessage({ id: "labelRowsSelect" })}
        />
      </TableContainer>{" "}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 40px;
  margin-bottom: 60px;

  @media screen and (max-width: 500px) {
    margin: 32px 16px;
  }
`;
// count={rows.length}
// rowsPerPage={rowsPerPage}
// page={page}
// onPageChange={handleChangePage}
// onRowsPerPageChange={handleChangeRowsPerPage}
